<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  name: 'App'
}
</script>

<style lang="less">
html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app {
  height: 100%;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration:none;
}

p,li,ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.form-info-edit {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .el-form-item {
    flex: 0 0 50%;
  }
  .form-info-edit-row {
    flex: 0 0 100%;
  }
}

.form-item-title {
  font-size: 16px;
  font-weight: 700;
  i {
    width: 5px;
    background-color: #465fd2;
    display: inline-block;
    height: 16px;
    vertical-align: middle;
    margin-top: -3px;
    margin-right: 10px;
  }
}

</style>
