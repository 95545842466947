<template>
    <div class="edit-dialog" v-if="visible">
        <div class="header" >
            <div class="edit-title">
              <i class="el-icon-arrow-left point" @click="goBack"></i>
              <span class="title point"  @click="goBack">返回</span>
              <span class="split"></span>
              <template v-if="!$slots.title">
                <span class="title">{{  title }}</span>
              </template>
              <template v-else>
                <slot name="title"></slot>
              </template>
            </div>
            <div class="edit-tools">
              <slot name="tools"></slot>
            </div>
        </div>

        <div class="content">
            <div :class="contentClass" :style="contentStyle">
                <slot></slot>
            </div>
        </div>
        <div class="floor" v-if="!view">
          <template v-if="!$slots.floor">
            <el-button type="primary" @click="$emit('submit')" >保存</el-button>
            <el-button @click="$emit('update:visible', false, true)">取消</el-button>
          </template>
          <template v-else>
            <slot name="floor"></slot>
          </template>
        </div>
    </div>
</template>
<script>
export default {
  props: {
    contentStyle: {
      default () {
        return {}
      }
    },
    fullscreen: {
      default: false
    },
    contentClass: {
      default () {
        return ''
      }
    },
    visible: {
      default: false
    },
    title: {
      default: ''
    },
    view: {
      default: false
    }
  },
  destroyed () {
    if (this.fullscreen) {
      this.$root.$emit('ft-fullsceen-event', false)
    }
  },
  watch: {
    visible: {
      immediate: true,
      handler (value) {
        if (this.fullscreen) {
          this.$root.$emit('ft-fullsceen-event', value)
        }
      }
    }
  },
  methods: {
    goBack () {
      this.$emit('update:visible', false)
    }

  }
}
</script>
<style lang="less" scoped>
.edit-dialog {
    position: absolute;
    background-color: #fff;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    z-index: 5;
    .header {
        flex: 0 auto;
        padding: 0px 20px;
        display: flex;
        flex-direction: row;
        font-size: 14px;
        background-color: #fff;
        font-weight: 500;
        border-radius: 4px 4px 0px 0px;
        box-shadow: 0px 1px 0px 0px #e6e7ef;
        .edit-title {
          padding: 13px 0px;
          display: flex;
          flex-direction: row;
          flex: 1 1 auto;
          .point {
              line-height: 22px;
              cursor: pointer;
                        margin-right: 5px;
          }
          .split {
              width: 0px;
              height: 23px;
              border-right: 1px solid #e6e6e6;
              margin-right: 10px;
              margin-left: 5px;
          }
          .title {
              height: 20px;
              font-size: 14px;
              font-family: PingFangSC, PingFangSC-Regular;
              font-weight: 400;
              text-align: left;
              color: rgba(42,42,44,0.98);
              line-height: 20px;
          }

        }
        .edit-tools   {
          flex: 0 0 auto;
        }
    }
    .content {
        padding: 20px;
        flex: 1 auto;
        overflow-y: auto;
    }
    .floor {
        flex: 0 auto;
        padding: 0px 30px;
        line-height: 72px;
        box-shadow: 0px -10px 10px -1px rgba(86,86,86,0.07);
    }

}
</style>
