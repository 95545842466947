import dayjs from 'dayjs'
import { DISPUTE_STATUS, MEDIATION_ROOM_STATUS, ENTRUST_TYPE, APPLY_STATUS, EDUCATION_TYPE, SIGN_STATUS, APPLY_TYPE, PRINCIPAL_LIST, PROOF_STATUS, PROOF_TYPE } from './constant'

export function dateFormat (date, format = 'YYYY-MM-DD HH:mm:ss') {
  if (!date) return '-'
  return dayjs(date).format(format)
}

export function hidePhone (phone) {
  if (!phone) {
    return ''
  } else {
    return phone.substr(0, 3) + '****' + phone.substr(7)
  }
}

export function hideIdCard (idCard) {
  if (!idCard) {
    return ''
  } else {
    return idCard.substr(0, 4) + '************' + idCard.substr(16)
  }
}

export function mediationStatus (status) {
  return ['已停用', '已启用'][status] || '-'
}

export function disputeStatus (status) {
  return DISPUTE_STATUS[status] || '-'
}

export function mediationRoomStatus (status) {
  return MEDIATION_ROOM_STATUS[status] || '-'
}

export function applyType (value) {
  if (Array.isArray(value)) {
    const rows = APPLY_TYPE.filter(row => (value || []).includes(row.value))
    if (rows.length > 0) {
      return rows.map(row => `${row.label}（${row.desc}）`).join(',')
    } else {
      return '-'
    }
  } else {
    const row = APPLY_TYPE.find(row => row.value === value)
    return row ? row.label : '-'
  }
}

export function roomIdFormat (value) {
  if (!value) {
    return '-'
  } else {
    const str = value.toString()
    let result = ''
    for (let i = str.length - 1; i >= 0; i--) {
      if (i % 3 === 0) {
        result = ' ' + str[i] + result
      } else {
        result = str[i] + result
      }
    }
    return result.trim()
  }
}

export function encrustType (type) {
  return ENTRUST_TYPE[type] || '-'
}

export function applyStatus (status) {
  return APPLY_STATUS[status] || '-'
}

export function educationType (value) {
  return EDUCATION_TYPE[value] || '-'
}

export function signStatus (val) {
  return SIGN_STATUS[val] || '-'
}

export function principal (val) {
  return PRINCIPAL_LIST[val] || '-'
}

export function proofStatus (val) {
  return PROOF_STATUS[val] || '-'
}

export function proofType (val) {
  return PROOF_TYPE[val] || '-'
}

export function sizeFormat (value) {
  if (value > 1024 * 1024) {
    return `${(value / (1024 * 1024)).toFixed(1)}M`
  } else {
    return `${(value / (1024)).toFixed(1)}KB`
  }
}

export default {
  install (Vue) {
    Vue.filter('dateFormat', dateFormat)
    Vue.filter('hidePhone', hidePhone)
    Vue.filter('mediationStatus', mediationStatus)
    Vue.filter('disputeStatus', disputeStatus)
    Vue.filter('mediationRoomStatus', mediationRoomStatus)
    Vue.filter('roomIdFormat', roomIdFormat)
    Vue.filter('encrustType', encrustType)
    Vue.filter('applyStatus', applyStatus)
    Vue.filter('educationType', educationType)
    Vue.filter('signStatus', signStatus)
    Vue.filter('applyType', applyType)
    Vue.filter('principal', principal)
    Vue.filter('proofStatus', proofStatus)
    Vue.filter('proofType', proofType)
    Vue.filter('sizeFormat', sizeFormat)
  }
}
