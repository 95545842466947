import { createInstance } from './axios'

class CurrentUser {
  info = null
  mediationInfo = null
  axios = createInstance('/mediator/api/v1')
  async loadData () {
    const res = await this.axios.get('/login/info')
    this.info = res.data.result
    const res1 = await this.axios.get('/mediation/info')
    this.mediationInfo = await res1.data.result
  }

  async updateData (data) {
    await this.axios.put('/login/info', data)
    await this.loadData()
  }

  get info () {
    return this.info
  }

  get token () {
    return localStorage.getItem('token')
  }

  set token (token) {
    localStorage.setItem('token', token)
  }

  get permission () {
    const josnStr = localStorage.getItem('permission') || '[]'
    return JSON.parse(josnStr)
  }

  set permission (permission) {
    localStorage.setItem('permission', JSON.stringify(permission || []))
  }

  get mediationId () {
    return localStorage.getItem('mediationId')
  }

  set mediationId (mediationId) {
    localStorage.setItem('mediationId', mediationId)
  }

  get mediationTitle () {
    return localStorage.getItem('mediationTitle')
  }

  set mediationTitle (title) {
    localStorage.setItem('mediationTitle', title)
  }

  clear () {
    localStorage.removeItem('token')
    localStorage.removeItem('permission')
    localStorage.removeItem('mediationId')
    localStorage.removeItem('mediationTitle')
  }
}

export default new CurrentUser()
